import {create} from "zustand";
import {combine, persist} from "zustand/middleware";
// @ts-ignore
import {UserConnected} from "../types/types.ts";

export const useUserConnectedStore = create(
    persist(
        combine(
            {
                userConnected: null as undefined | null | UserConnected,
            },
            (set) => ({
              setUserConnected: (userConnected: UserConnected | null) => set({userConnected: userConnected}),
            }),
        ),
        {name: "user"}
    )
);
