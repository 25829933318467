import {useCallback, useState} from "react";
// @ts-ignore
import {useUserConnectedStore} from "../store/store.ts";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import {jwtDecode} from "jwt-decode";


export enum AuthStatus {

  Unknown = 0,
  Authenticated = 1,
  Guest = 2,

}

export function useAuth() {

  const {userConnected, setUserConnected} = useUserConnectedStore();
  const navigate = useNavigate();
  //type error or success
  const [messageFlash, setMessageFlash] = useState(null);
  //Api url for fetch
  const api_url = process.env.REACT_APP_API_URL;

  let status: AuthStatus;

  switch (userConnected) {
    case null:
      status = AuthStatus.Guest;
      break;
    case undefined:
      status = AuthStatus.Unknown;
      break;
    default:
      status = AuthStatus.Authenticated;
      break;
  }

  const authenticate = useCallback(() => {
    const token = Cookies.get('tokenLicense');

    if (!token) {
      setUserConnected(null);
      navigate('/login_check');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);

      const currentTime = Date.now().valueOf() / 1000;
      if (decodedToken.exp < currentTime) {
        setUserConnected(null);
        navigate('/login_check');
        return;
      }

      // @ts-ignore
      setUserConnected(userConnected);

    } catch (error) {
      console.error(error);
      setUserConnected(null);
      navigate('/login_check');
    }
  }, []);

  const login = useCallback((username: string, password: string) => {

    // @ts-ignore
    fetch(`${api_url}/api/login_check`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),

    }).then((response) => {
      if (response.ok) {

        return response.json();
      }
      return response.json().then(data => { //read the response
        throw new Error(data.error);
      });
    }).then((data) => {
      setUserConnected(data);
      Cookies.set('tokenLicense', data.token);
      navigate("/dashboard")
    }).catch((error) => {
      setMessageFlash({type: "error", message: error.message})
    });

  }, []);


  return {
    userConnected,
    status,
    authenticate,
    login,
    messageFlash,
  };

}
